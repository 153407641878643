import React, { useMemo } from 'react';
import { Space } from 'antd';
import clsx from 'clsx';
import { MENU_ITEMS } from 'constants/index';
import styles from './styles.module.scss';
import { useChangeSideMenu } from 'hooks/index';
import { useGlobal } from 'context/GlobalProvider';

export default function Sider() {
  const changeSideMenu = useChangeSideMenu();
  const [{ activeMenuKey }] = useGlobal();

  const activeMenuKeyFormat = useMemo(() => {
    return activeMenuKey; // || localStorage?.getItem(SIDE_KEY); TODO
  }, [activeMenuKey]);

  return (
    <div className={clsx('flex-column-between', styles['menu-container'])}>
      <Space className={styles['menu-items-wrapper']} direction="vertical">
        {MENU_ITEMS.map((item) => {
          const MenuIcon = item.icon;
          return (
            <div
              key={item.key}
              className={clsx('cursor-pointer', 'flex-row-center', styles['menu-item'], {
                [styles['menu-item-active']]: item.key === activeMenuKeyFormat,
              })}
              onClick={() => {
                changeSideMenu(item.key);
              }}>
              <MenuIcon className={clsx('flex-none', styles['menu-item-icon'])} />
              <span className={styles['menu-item-label']}>{item.label}</span>
            </div>
          );
        })}
      </Space>
    </div>
  );
}
