import { TBaseConfig } from '@etransfer/request';
import { TAuthApiBaseParams } from '@etransfer/types';

export const DEFAULT_METHOD = 'GET';

const AuthList: Record<string, TBaseConfig> = {
  login: {
    target: '/connect/token',
    baseConfig: { method: 'POST' },
  },
  changePassword: {
    target: '/api/reconciliation/change-password',
    baseConfig: { method: 'POST' },
  },
};

const CommonApiList: Record<string, TBaseConfig> = {
  getOrderDetail: '/api/reconciliation/record',
  getOrderFilter: '/api/reconciliation/network/option',
};

const DepositApiList: Record<string, TBaseConfig> = {
  getDepositOrder: '/api/reconciliation/deposit-order',
};

const TransferApiList: Record<string, TBaseConfig> = {
  getTransferOrder: '/api/reconciliation/withdraw-order',
};

const FailedApiList: Record<string, TBaseConfig> = {
  getFailedOrder: '/api/reconciliation/fail-order',
  releaseRequest: {
    target: '/api/reconciliation/release-request',
    baseConfig: { method: 'POST' },
  },
  refundRequest: {
    target: '/api/reconciliation/refund-request',
    baseConfig: { method: 'POST' },
  },
  release: {
    target: '/api/reconciliation/release',
    baseConfig: { method: 'POST' },
  },
  refund: {
    target: '/api/reconciliation/refund',
    baseConfig: { method: 'POST' },
  },
  releaseReject: {
    target: '/api/reconciliation/release-reject',
    baseConfig: { method: 'POST' },
  },
  refundReject: {
    target: '/api/reconciliation/refund-reject',
    baseConfig: { method: 'POST' },
  },
  transferReleaseRequest: {
    target: '/api/reconciliation/transfer-release-request',
    baseConfig: { method: 'POST' },
  },
  transferRelease: {
    target: '/api/reconciliation/transfer-release',
    baseConfig: { method: 'POST' },
  },
  transferReleaseReject: {
    target: '/api/reconciliation/transfer-release-reject',
    baseConfig: { method: 'POST' },
  },
};

export const API_LIST: Record<string, Record<string, TBaseConfig>> = {
  auth: AuthList,
  common: CommonApiList,
  deposit: DepositApiList,
  transfer: TransferApiList,
  failed: FailedApiList,
};

export const AUTH_API_BASE_PARAMS: TAuthApiBaseParams = {
  grant_type: 'login_credentials',
  scope: 'ETransferServer',
  client_id: 'ETransferServer_App',
};
