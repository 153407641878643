'use client';
import React from 'react';
import { ETransferLayoutProvider, ETransferStyleProvider } from '@etransfer/ui-react';
import { GlobalProvider } from 'context/GlobalProvider';
import { useRouter } from 'next/navigation';
import { useEffectOnce } from 'react-use';
import { etransferEvents } from '@etransfer/utils';
import { PageRouterKey } from 'constants/index';

import Layout from './layout';

const ContainerLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  useEffectOnce(() => {
    const { remove } = etransferEvents.DeniedRequest.addListener(() => {
      router.push(PageRouterKey.Home);
    });
    return () => {
      remove();
    };
  });

  return (
    <GlobalProvider>
      <ETransferStyleProvider>
        <ETransferLayoutProvider>
          <Layout>{children}</Layout>
        </ETransferLayoutProvider>
      </ETransferStyleProvider>
    </GlobalProvider>
  );
};

export default ContainerLayout;
