import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Logo } from 'assets/images';
import styles from './styles.module.scss';
import { ETRANSFER_WEBSITE_URL, PageRouterKey } from 'constants/index';
import { useGlobal } from 'context/GlobalProvider';
import { useRouter } from 'next/navigation';

export default function WebHeader() {
  const [{ username }] = useGlobal();
  const router = useRouter();

  const goWebsite = useCallback(() => {
    window.open(ETRANSFER_WEBSITE_URL, '_self');
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    router.push(PageRouterKey.Home);
  }, [router]);

  return (
    <div className={clsx('flex-row-between', styles['header-container'])}>
      <div className={styles['header-logo']} onClick={goWebsite}>
        <Logo />
      </div>
      <div className={styles['right-wrapper']}>
        <div className={styles['header-username']}>{username}</div>
        <div className={styles['header-logout']} onClick={handleLogout}>
          Log out
        </div>
      </div>
    </div>
  );
}
