import { PageRouterKey, SideMenuKey } from 'constants/index';
import { useGlobal } from 'context/GlobalProvider';
import { globalAction } from 'context/GlobalProvider/actions';
import { useRouter } from 'next/navigation';
import { useRef, useCallback } from 'react';

export function useGlobalDispatch() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { dispatch }] = useGlobal();
  return dispatch;
}

export function useChangeSideMenu() {
  const dispatch = useGlobalDispatch();
  const router = useRouter();
  const routerRef = useRef(router.push);
  routerRef.current = router.push;

  return useCallback(
    (key: SideMenuKey) => {
      console.log('>>>useChangeSideMenu', key);

      dispatch(globalAction.setActiveMenuKey.actions(key));
      routerRef.current(PageRouterKey[key]);
    },
    [dispatch],
  );
}
