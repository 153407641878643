import { AxiosRequestConfig } from 'axios';
import { stringify } from 'query-string';
import { TGetAuthResult } from '@etransfer/types';
import {
  TChangePasswordRequest,
  TChangePasswordResult,
  TGetAuthRequest,
  TGetDepositOrderRequest,
  TGetDepositOrderResult,
  TGetFailedOrderRequest,
  TGetFailedOrderResult,
  TGetTransferOrderRequest,
  TGetTransferOrderResult,
  TOrderFilterResult,
  TRefundRejectRequest,
  TRefundRejectResult,
  TRefundRequest,
  TRefundRequestRequest,
  TRefundRequestResult,
  TRefundResult,
  TReleaseRejectRequest,
  TReleaseRejectResult,
  TReleaseRequest,
  TReleaseRequestRequest,
  TReleaseRequestResult,
  TReleaseResult,
  TServices,
} from './types';
import { formatApiError } from './utils';
import { API_LIST, AUTH_API_BASE_PARAMS } from './constants';
import { TRequestConfig, EtransferRequest } from '@etransfer/request';
import { TGetRecordDetailResult } from 'types/order';
import { ETransferHost } from 'constants/index';

export abstract class BaseService {
  protected readonly _request: EtransferRequest;

  public constructor() {
    this._request = new EtransferRequest();
    this.setRequestConfig('baseURL', ETransferHost);
  }

  public setRequestConfig(key: keyof TRequestConfig, value: TRequestConfig[keyof TRequestConfig]) {
    this._request.setConfig(key, value);
  }

  public setRequestHeaders(key: string, value: string) {
    this._request.setHeaders(key, value);
  }

  public getRequest() {
    return this._request;
  }
}

export class Services extends BaseService implements TServices {
  async getAuthToken(
    params: TGetAuthRequest,
    config?: AxiosRequestConfig<any>,
  ): Promise<TGetAuthResult> {
    try {
      if (typeof API_LIST.auth.login === 'string') {
        throw new Error('Please set correct post api url');
      }

      const res = await this._request.post(
        API_LIST.auth.login.target,
        stringify({ ...AUTH_API_BASE_PARAMS, ...params }),
        {
          ...config,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      );
      return res.data;
    } catch (error) {
      throw formatApiError(error, 'getAuthToken error', false);
    }
  }

  async changePassword(params: TChangePasswordRequest): Promise<TChangePasswordResult> {
    try {
      if (typeof API_LIST.auth.changePassword === 'string') {
        throw new Error('Please set correct post api url');
      }

      const res = await this._request.post(API_LIST.auth.changePassword.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error) {
      throw formatApiError(error, 'changePassword error', false);
    }
  }

  async getDepositOrder(params: TGetDepositOrderRequest): Promise<TGetDepositOrderResult> {
    try {
      const res = await this._request.send(API_LIST.deposit.getDepositOrder, { params });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'getDepositOrder error', false);
    }
  }

  async getTransferOrder(params: TGetTransferOrderRequest): Promise<TGetTransferOrderResult> {
    try {
      const res = await this._request.send(API_LIST.transfer.getTransferOrder, {
        params,
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'getTransferOrder error', true);
    }
  }

  async getFailedOrder(params: TGetFailedOrderRequest): Promise<TGetFailedOrderResult> {
    try {
      const res = await this._request.send(API_LIST.failed.getFailedOrder, {
        params,
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'getFailedOrder error', true);
    }
  }

  async getOrderFilter(): Promise<TOrderFilterResult> {
    try {
      const res = await this._request.send(API_LIST.common.getOrderFilter, {});
      return res.data;
    } catch (error) {
      throw formatApiError(error, 'orderFilter error', false);
    }
  }

  async getOrderDetail(id: string): Promise<TGetRecordDetailResult> {
    try {
      const res = await this._request.send(API_LIST.common.getOrderDetail, { query: id });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'getOrderDetail error', false);
    }
  }

  async releaseRequest(params: TReleaseRequestRequest): Promise<TReleaseRequestResult> {
    try {
      if (typeof API_LIST.failed.releaseRequest === 'string') {
        throw new Error('Please set correct post api url');
      }

      const res = await this._request.post(API_LIST.failed.releaseRequest?.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });

      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'releaseRequest error', false);
    }
  }

  async transferReleaseRequest(params: TReleaseRequestRequest): Promise<TReleaseRequestResult> {
    try {
      if (typeof API_LIST.failed.transferReleaseRequest === 'string') {
        throw new Error('Please set correct post api url');
      }

      const res = await this._request.post(API_LIST.failed.transferReleaseRequest?.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });

      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'releaseRequest error', false);
    }
  }

  async refundRequest(params: TRefundRequestRequest): Promise<TRefundRequestResult> {
    try {
      if (typeof API_LIST.failed.refundRequest === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.refundRequest.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'refundRequest error', false);
    }
  }

  async release(params: TReleaseRequest): Promise<TReleaseResult> {
    try {
      if (typeof API_LIST.failed.release === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.release.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'release error', false);
    }
  }

  async transferRelease(params: TReleaseRequest): Promise<TReleaseResult> {
    try {
      if (typeof API_LIST.failed.transferRelease === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.transferRelease.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'release error', false);
    }
  }

  async refund(params: TRefundRequest): Promise<TRefundResult> {
    try {
      if (typeof API_LIST.failed.refund === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.refund.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'refund error', false);
    }
  }

  async releaseReject(params: TReleaseRejectRequest): Promise<TReleaseRejectResult> {
    try {
      if (typeof API_LIST.failed.releaseReject === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.releaseReject.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'releaseReject error', false);
    }
  }

  async transferReleaseReject(params: TReleaseRejectRequest): Promise<TReleaseRejectResult> {
    try {
      if (typeof API_LIST.failed.transferReleaseReject === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.transferReleaseReject.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'releaseReject error', false);
    }
  }

  async refundReject(params: TRefundRejectRequest): Promise<TRefundRejectResult> {
    try {
      if (typeof API_LIST.failed.refundReject === 'string') {
        throw new Error('Please set correct post api url');
      }
      const res = await this._request.post(API_LIST.failed.refundReject.target, params, {
        headers: { 'Content-Type': 'application/json' },
      });
      return res.data;
    } catch (error: any) {
      throw formatApiError(error, 'refundReject error', false);
    }
  }
}

const services = new Services();

export default services;
