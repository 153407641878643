import { basicActions } from '../utils';
import { SideMenuKey } from 'constants/index';

export const GlobalActions = {
  initialized: 'INITIALIZED',
  destroy: 'DESTROY',
  setActiveMenuKey: 'setActiveMenuKey',
  setUsername: 'setUsername',
};

export interface GlobalState {
  activeMenuKey?: SideMenuKey;
  username?: string;
}

export const globalAction = {
  initialized: {
    type: GlobalActions['initialized'],
    actions: (initialized: GlobalState) =>
      basicActions(GlobalActions['initialized'], { initialized }),
  },
  setActiveMenuKey: {
    type: GlobalActions['setActiveMenuKey'],
    actions: (key: SideMenuKey) => basicActions(GlobalActions['setActiveMenuKey'], { key }),
  },
  setUsername: {
    type: GlobalActions['setUsername'],
    actions: (name: string) => basicActions(GlobalActions['setUsername'], { name }),
  },

  destroy: {
    type: GlobalActions['destroy'],
    actions: () => basicActions(GlobalActions['destroy']),
  },
};
