import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { BasicActions } from '../utils';
import { GlobalActions, GlobalState } from './actions';
import { SIDE_KEY } from 'constants/storage';

const INITIAL_STATE = {
  activeMenuKey: '',
  username: '',
};

const GlobalContext = createContext<any>(INITIAL_STATE);

export function useGlobal(): [GlobalState, BasicActions] {
  return useContext(GlobalContext);
}

//reducer
function reducer(state: GlobalState, { type, payload }: any) {
  switch (type) {
    case GlobalActions.setActiveMenuKey: {
      const key = payload.key;
      if (!key) return state;

      localStorage.setItem(SIDE_KEY, key);
      return Object.assign({}, state, { activeMenuKey: key });
    }
    case GlobalActions.setUsername: {
      const name = payload.name;
      if (!name) return state;

      return Object.assign({}, state, { username: name });
    }
    case GlobalActions.destroy: {
      return INITIAL_STATE;
    }
    default: {
      return Object.assign({}, state, payload);
    }
  }
}

export function GlobalProvider({
  children,
  ...props
}: { children: React.ReactNode } & GlobalState) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <GlobalContext.Provider
      value={useMemo(() => [{ ...state, ...props }, { dispatch }], [props, state])}>
      {children}
    </GlobalContext.Provider>
  );
}
