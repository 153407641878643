'use client';
import React, { Suspense, useEffect } from 'react';
import { Layout as AntdLayout } from 'antd';
import Header from 'components/Header';
import Sider from 'components/Sider';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { CircleLoading } from '@etransfer/ui-react';
import { useGlobalDispatch } from 'hooks';
import { usePathname, useRouter } from 'next/navigation';
import { useEffectOnce } from 'react-use';
import { etransferEvents } from '@etransfer/utils';
import { PageRouterKey } from 'constants/index';
import { AUTH_STORAGE_KET, USERNAME_STORAGE_KET } from 'constants/storage';
import services from 'services/services';
import { globalAction } from 'context/GlobalProvider/actions';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const router = useRouter();
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    const auth = localStorage.getItem(AUTH_STORAGE_KET);
    const authParse = auth ? JSON.parse(auth) : {};
    const username = localStorage.getItem(USERNAME_STORAGE_KET);

    if (username && auth && authParse[username]) {
      const jwt = authParse[username];
      services.setRequestHeaders('Authorization', `${jwt.token_type} ${jwt.access_token}`);

      dispatch(globalAction.setUsername.actions(username));
    }
  }, [dispatch]);

  useEffectOnce(() => {
    const { remove } = etransferEvents.DeniedRequest.addListener(() => {
      router.push(PageRouterKey.Home);
    });
    return () => {
      remove();
    };
  });

  return (
    <AntdLayout
      id="etransferWebWrapper"
      className={clsx(
        styles['etransfer-web-wrapper'],
        styles['layout-wrapper'],
        styles['layout-wrapper-weight'],
      )}>
      {pathname !== '/' ? <Header /> : null}
      <div
        className={clsx(
          'flex-row',
          styles['layout-content-wrapper'],
          styles['layout-content-wrapper-with-header'],
        )}>
        {pathname !== '/' ? <Sider /> : null}
        <div className={styles['etransfer-web-content']}>
          <Suspense fallback={<CircleLoading />}>{children}</Suspense>
        </div>
      </div>
    </AntdLayout>
  );
};

export default Layout;
