export * from './mainnet';

import { SettingOutlined } from '@ant-design/icons';
import { Records } from 'assets/images';
export const BRAND_NAME = 'ETransferDashboard';
export const LOGIN_PAGE_TITLE = 'ETransfer Dashboard';
export const ETRANSFER_WEBSITE_URL = 'https://etransfer.exchange';

export enum SideMenuKey {
  Deposit = 'Deposit',
  Transfer = 'Transfer',
  Failed = 'Failed',
  Setting = 'Setting',
}

export enum PageRouterKey {
  Home = '/',
  Deposit = '/deposit',
  Transfer = '/transfer',
  Failed = '/failed',
  Setting = '/setting',
  TransferDetail = 'transfer-detail',
}

export const MENU_ITEMS = [
  {
    icon: Records,
    key: SideMenuKey.Deposit,
    label: SideMenuKey.Deposit,
  },
  {
    icon: Records,
    key: SideMenuKey.Transfer,
    label: SideMenuKey.Transfer,
  },
  {
    icon: Records,
    key: SideMenuKey.Failed,
    label: SideMenuKey.Failed,
  },
  {
    icon: SettingOutlined,
    key: SideMenuKey.Setting,
    label: SideMenuKey.Setting,
  },
];

export const FORM_SIZE = 'large';
